// Importación de componentes necesarios y librerías
import Sidebar from "../components/Layouts/Sidebar";
import Header from "../components/Layouts/Header";
import Table from "../components/Layouts/Tabla";
import InputField from "../components/Layouts/InputField";
import SelectField from "../components/Layouts/SelectField";
import { useState, useEffect } from "react";
import axios from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import iconTxt from "../assets/txt.svg";
import iconPdf from "../assets/pdf.svg";
import iconExcel from "../assets/excel.svg";
import Notification from "../components/Layouts/Notificacion";

function Consultas() {
  // Definición de estados
  const [formType, setFormType] = useState(""); // Tipo de formulario seleccionado
  const [data, setData] = useState([]); // Datos obtenidos de la API
  const [searchQuery, setSearchQuery] = useState(""); // Consulta de búsqueda
  const [startDate, setStartDate] = useState(""); // Fecha de inicio para el filtro
  const [endDate, setEndDate] = useState(""); // Fecha de fin para el filtro
  const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error

  // useEffect para obtener los datos al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = 'http://localhost:5000/data';
      try {
        const response = await axios.get(apiUrl);
        const result = response.data;

        // Formatear las fechas del resultado
        const formattedResult = result.map(item => ({
          ...item,
          fEntrada: convertDateFormat(item.fEntrada),
          fSalida: convertDateFormat(item.fSalida)
        }));

        if (Array.isArray(formattedResult)) {
          setData(formattedResult); // Asignar datos al estado
        } else {
          setErrorMessage("La respuesta de la API no es un arreglo.");
        }
      } catch (error) {
        setErrorMessage("Error fetching data: " + error.message);
      }
    };

    fetchData();
  }, []);

  // Función para convertir el formato de fecha
  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  // Filtrar datos basado en la búsqueda, fechas y tipo de formulario
  const filteredData = data
    .filter((item) => {
      const itemFEntrada = new Date(item.fEntrada);
      const itemFSalida = new Date(item.fSalida);
      const validItemFEntrada = !isNaN(itemFEntrada.getTime());
      const validItemFSalida = !isNaN(itemFSalida.getTime());

      if (!validItemFEntrada || !validItemFSalida) return false;

      return (
        (searchQuery === "" ||
          Object.values(item).some((val) =>
            val.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )) &&
        (startDate === "" || itemFEntrada >= new Date(startDate)) &&
        (endDate === "" || itemFSalida <= new Date(endDate)) &&
        (formType === "" || item.tipo === formType)
      );
    });

  // Configuración de columnas basado en el tipo de formulario
  const columnConfig = {
    Ingreso: [
      { name: "tiquete", title: "Tiquete" },
      { name: "tipo", title: "Tipo" },
      { name: "placa", title: "Placa" },
      { name: "cedula", title: "Cedula Conducto" },
      { name: "conductor", title: "Conductor" },
      { name: "trailer", title: "Trailer" },
      { name: "cod_Proveedor", title: "Cod Proveedor" },
      { name: "nombre", title: "Nombre" },
      { name: "cod_Comprador", title: "Cod Comprador" },
      { name: "nombre_Comprador", title: "Nombre" },
      { name: "cod_Producto", title: "Cod Producto" },
      { name: "nombre_Producto", title: "Nombre" },
      { name: "cod_Origen", title: "Cod Origen" },
      { name: "nombre_Origen", title: "Origen" },
      { name: "cod_Destino", title: "Cod Destino" },
      { name: "nombre_Destino", title: "Destino" },
      { name: "fEntrada", title: "F Entrada" },
      { name: "hEntrada", title: "H Entrada" },
      { name: "pesoBruto", title: "Peso Bruto" },
      { name: "fSalida", title: "F Salida" },
      { name: "hSalida", title: "H Salida" },
      { name: "pesoTara", title: "Peso Tara" },
      { name: "pesoNeto", title: "Peso Neto" },
    ],
    Despacho: [
      { name: "tiquete", title: "Tiquete" },
      { name: "tipo", title: "Tipo" },
      { name: "placa", title: "Placa" },
      { name: "cedula", title: "Cedula Conducto" },
      { name: "conductor", title: "Conductor" },
      { name: "trailer", title: "Trailer" },
      { name: "cod_Cliente", title: "Cod Cliente" },
      { name: "nombre_Cliente", title: "Nombre" },
      { name: "transportadora", title: "Transportadora" },
      { name: "orden", title: "Orden" },
      { name: "precintos", title: "Precintos" },
      { name: "cod_Producto", title: "Cod Producto" },
      { name: "nombre_Producto", title: "Nombre" },
      { name: "patio", title: "Patio" },
      { name: "origen", title: "Origen" },
      { name: "cod_Destino", title: "Cod Destino" },
      { name: "destino", title: "Destino" },
      { name: "fEntrada", title: "F Entrada" },
      { name: "hEntrada", title: "H Entrada" },
      { name: "pesoBruto", title: "Peso Bruto" },
      { name: "fSalida", title: "F Salida" },
      { name: "hSalida", title: "H Salida" },
      { name: "pesoTara", title: "Peso Tara" },
      { name: "pesoNeto", title: "Peso Neto" },
    ],
  };

  // Opciones para el selector de tipo de formulario
  const formTypes = [
    { value: "", label: "Seleccione" },
    { value: "Ingreso", label: "Ingresos" },
    { value: "Despacho", label: "Despachos" },
  ];

  // Selección de columnas basado en el tipo de formulario
  const columns = columnConfig[formType] || columnConfig.Ingreso;

  return (
    <div className="h-screen w-screen font-montserrat bg-[#F2F2F2]">
      {/* Componente de notificación para mostrar mensajes de error */}
      <Notification message={errorMessage} type="error" />
      <div className="flex h-full">
        <Sidebar />
        <div className="flex flex-col flex-1 w-screen h-screen overflow-hidden">
          <Header />
          <div className="m-2 p-1 h-screen">
            <div className="m-1 p-1 text-sm xl:text-lg">
              <div className="bg-white flex w-1/3 h-12 m-2 content-center rounded-lg items-center">
                <MagnifyingGlassIcon className="w-5 h-5 ml-2" />
                <div className="ml-4 w-11/12 h-full">
                  {/* Campo de búsqueda */}
                  <input
                    className="w-11/12 h-full"
                    placeholder="Buscar..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="bg-white flex w-full h-16 m-2 content-center rounded-lg items-center">
                {/* Campo de entrada para la fecha de inicio */}
                <InputField
                  label="Desde"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                {/* Campo de entrada para la fecha de fin */}
                <InputField
                  label="Hasta"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                {/* Selector de tipo de formulario */}
                <SelectField
                  label="Tipo"
                  id="formType"
                  options={formTypes}
                  value={formType}
                  onChange={(e) => setFormType(e.target.value)}
                />
              </div>
            </div>
            <div className="m-1 h-2/5  overflow-scroll text-sm xl:text-lg">
              {/* Tabla que muestra los datos filtrados */}
              <Table
                columns={columns}
                data={filteredData}
                onEdit={() => {}}
                onDelete={() => {}}
              />
            </div>
            <div className="bg-white w-full h-20 m-1 flex content-center justify-center rounded-lg items-center">
              <div className="m-2 flex items-center justify-center">
                <img src={iconTxt} alt="txt" className="m-2 w-10 h-10" />
                <img src={iconPdf} alt="pdf" className="m-2 w-10 h-10" />
                <img src={iconExcel} alt="excel" className="m-2 w-10 h-10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consultas;



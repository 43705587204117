import { useEffect, useState } from 'react';

const Notification = ({ message, type, onConfirm, onCancel }) => {
  // Estado para controlar la visibilidad de la notificación
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Si el tipo es 'error' y no hay una función de confirmación proporcionada
    if (type === 'error' && !onConfirm) {
      // Ocultar automáticamente después de 6 segundos si es un mensaje de error
      const timer = setTimeout(() => {
        setVisible(false); // Oculta la notificación
      }, 6000);
      // Limpiar el temporizador cuando el componente se desmonte o cambien los valores de type y onConfirm
      return () => clearTimeout(timer);
    }
  }, [type, onConfirm]);

  // Si la notificación no es visible o no hay mensaje, no renderizar nada
  if (!visible || !message) return null;

  // Definir estilos para los diferentes tipos de notificaciones
  const notificationStyles = {
    success: "bg-green-500",
    error: "bg-red-500",
    confirm: "bg-orange-500", // Puedes ajustar los estilos según tus necesidades
  };

  return (
    <div className={`${notificationStyles[type]} fixed top-0 left-0 w-full p-4 mb-4 text-white font-semibold z-50`}>
      <p>{message}</p> {/* Muestra el mensaje de la notificación */}
    </div>
  );
};

export default Notification;


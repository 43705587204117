const SelectField = ({ label, id, options = [], onChange }) => {
  return (
    <div className="ml-4 p-2 flex w-11/12 ">
      {/* Etiqueta para el campo de selección */}
      <label className="text-base w-1/4 font-bold m-2 block mr-4" htmlFor={id}>
        {label}
      </label>
      {/* Campo de selección */}
      <select
        id={id}
        onChange={onChange} // Llama a la función proporcionada cuando cambia la selección
        className="shadow w-3/4 text-base appearance-none border-2 border-[#6D80A6] rounded px-3 text-[#182540] leading-tight focus:outline-none focus:shadow-outline"
      >
        {/* Renderiza las opciones del menú desplegable */}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;




import React, { createContext, useContext, useState } from 'react';

// Crea un contexto para la autenticación
const AuthContext = createContext();

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
  // Estado para manejar si el usuario está autenticado
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Estado para manejar los errores de autenticación
  const [authError, setAuthError] = useState(null);

  // Función para iniciar sesión
  const login = (credentials) => {
    // Aquí puedes agregar la lógica de autenticación
    if (credentials.username === 'Hola' && credentials.password === 'Hola123') {
      setIsAuthenticated(true); // Usuario autenticado exitosamente
      setAuthError(null);        // Limpiar cualquier error previo
    } else {
      setIsAuthenticated(false); // Fallo en la autenticación
      setAuthError('Usuario o contraseña incorrectos'); // Mensaje de error
    }
  };

  // Función para cerrar sesión
  const logout = () => {
    setIsAuthenticated(false); // Usuario desautenticado
  };

  // Proporciona el contexto a los componentes hijos
  return (
    <AuthContext.Provider value={{ isAuthenticated, authError, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);


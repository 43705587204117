import { useEffect, useState, useRef } from "react";
import Notification from "../Layouts/Notificacion";
import { ChevronDownIcon } from '@heroicons/react/24/solid'; // Ajustar según la versión instalada

const Table = ({ columns, data }) => {
  // Estado para almacenar los datos de la tabla
  const [tableData, setTableData] = useState([]);
  // Estado para mostrar notificaciones
  const [notification, setNotification] = useState({});
  // Estado para los filtros aplicados
  const [filters, setFilters] = useState({});
  // Estado para las opciones de filtro por columna
  const [filterOptions, setFilterOptions] = useState({});
  // Estado para controlar la visibilidad de los menús desplegables
  const [dropdownVisible, setDropdownVisible] = useState({}); 

  // Refs para los menús desplegables, se usan para detectar clics fuera de ellos
  const dropdownRefs = useRef({});

  // Actualiza los datos de la tabla y las opciones de filtro cuando cambian los datos
  useEffect(() => {
    setTableData(data);
    updateFilterOptions(data);
  }, [data]);

  // Maneja el clic fuera de los menús desplegables para cerrarlos
  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach(column => {
        if (dropdownRefs.current[column] && !dropdownRefs.current[column].contains(event.target)) {
          setDropdownVisible(prevVisible => ({
            ...prevVisible,
            [column]: false
          }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Actualiza las opciones de filtro basadas en los datos de la tabla
  const updateFilterOptions = (data) => {
    const options = {};
    columns.forEach((column) => {
      const uniqueValues = Array.from(new Set(data.map(record => record[column.name])));
      options[column.name] = ['Todo', ...uniqueValues.filter(value => value !== undefined && value !== null && value !== '')];
    });
    setFilterOptions(options);
  };

  // Maneja los cambios en los filtros aplicados
  const handleFilterChange = (column, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: value === 'Todo' ? null : value
    }));
    setDropdownVisible(prevVisible => ({
      ...prevVisible,
      [column]: false // Oculta el menú después de seleccionar una opción
    }));
  };

  // Filtra los datos de la tabla según los filtros aplicados
  const filteredData = tableData.filter(record => {
    return Object.keys(filters).every(column => {
      if (!filters[column]) return true;
      return record[column] === filters[column];
    });
  });

  // Renderiza el encabezado de la tabla con menús desplegables para filtros
  const renderHeader = () => (
    <thead className="sticky top-0 bg-white">
      <tr>
        {columns.map((column) => (
          <th key={column.name} className="text-left py-2 px-4 border-b border-gray-200 relative">
            <div className="flex items-center">
              <span>{column.title}</span>
              <button
                onClick={() => setDropdownVisible(prevVisible => ({
                  ...prevVisible,
                  [column.name]: !prevVisible[column.name]
                }))}
                className="ml-2 p-1 text-gray-500 hover:text-gray-700 relative"
                ref={el => dropdownRefs.current[column.name] = el}
              >
                <ChevronDownIcon className="w-5 h-5" />
                {dropdownVisible[column.name] && (
                  <div className="absolute left-0 mt-2  bg-white border border-gray-300 shadow-lg z-10">
                    {filterOptions[column.name]?.map(option => (
                      <button
                        key={option}
                        onClick={() => handleFilterChange(column.name, option)}
                        className="block px-4 text-sm py-2 text-left hover:bg-gray-100"
                      >
                        {option || 'N/A'}
                      </button>
                    ))}
                  </div>
                )}
              </button>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

  // Renderiza el cuerpo de la tabla con los datos filtrados
  const renderBody = () => (
    <tbody>
      {filteredData.map((record, rowIndex) => (
        <tr
          key={rowIndex}
          className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-100"}
        >
          {columns.map((column) => (
            <td
              key={column.name}
              className="py-2 px-4 border-b"
            >
              {column.render ? column.render(record) : (
                record[column.name] || 'N/A'
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      {/* Componente de notificación */}
      <Notification
        message={notification.message}
        type={notification.type}
        onConfirm={notification.onConfirm}
        onCancel={notification.onCancel}
      />
      <table className="w-full border-collapse">
        {renderHeader()}
        {renderBody()}
      </table>
    </div>
  );
};

export default Table;


import Sidebar from "../components/Layouts/Sidebar"; // Importa el componente Sidebar
import Header from "../components/Layouts/Header"; // Importa el componente Header
import Table from "../components/Layouts/Tabla"; // Importa el componente Table
import { useEffect, useState } from "react"; // Importa los hooks useEffect y useState de React
import axios from "axios"; // Importa la librería axios para realizar solicitudes HTTP
import Notification from "../components/Layouts/Notificacion"; // Importa el componente Notification

// Datos estáticos que se muestran en el componente
const staticData = {
  ingresos: [
    { label: "Altos", value: "120.000 Tn", change: "10.26%" },
    { label: "Bajos", value: "236.000 Tn", change: "18.26%" },
    { label: "Medios", value: "236.000 Tn", change: "18.26%" },
  ],
};

function Inicio() {
  // Define estados para almacenar los datos de la API, mensajes de error y el estado de carga
  const [apiData, setApiData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  // useEffect para realizar la solicitud de datos a la API al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "http://localhost:5000/data"; // URL de la API
      try {
        const response = await axios.get(apiUrl); // Realiza una solicitud GET a la API
        const result = response.data;

        // Verifica si la respuesta de la API es un arreglo
        if (Array.isArray(result)) {
          setApiData(result); // Actualiza el estado con los datos de la API
        } else {
          setErrorMessage("La respuesta de la API no es un arreglo."); // Maneja el caso en que la respuesta no es un arreglo
        }
      } catch (error) {
        setErrorMessage("Error fetching data: " + error.message); // Maneja errores de solicitud
      } finally {
        setLoading(false); // Actualiza el estado de carga a false
      }
    };

    fetchData(); // Llama a la función fetchData para obtener los datos de la API
  }, []);

  // Define las columnas para la tabla
  const columns = [
    { name: "tiquete", title: "Tiquete" },
    { name: "tipo", title: "Tipo" },
    { name: "placa", title: "Placa" },
    { name: "cedula", title: "Cedula Conducto" },
    { name: "conductor", title: "Conductor" },
    { name: "trailer", title: "Trailer" },
    { name: "cod_Proveedor", title: "Cod Proveedor" },
    { name: "nombre", title: "Nombre" },
    { name: "cod_Comprador", title: "Cod Comprador" },
    { name: "nombre_Comprador", title: "Nombre" },
    { name: "cod_Producto", title: "Cod Producto" },
    { name: "nombre_Producto", title: "Nombre" },
    { name: "cod_Origen", title: "Cod Origen" },
    { name: "nombre_Origen", title: "Origen" },
    { name: "cod_Destino", title: "Cod Destino" },
    { name: "nombre_Destino", title: "Destino" },
    { name: "fEntrada", title: "F Entrada" },
    { name: "hEntrada", title: "H Entrada" },
    { name: "pesoBruto", title: "Peso Bruto" },
    { name: "fSalida", title: "F Salida" },
    { name: "hSalida", title: "H Salida" },
    { name: "pesoTara", title: "Peso Tara" },
    { name: "pesoNeto", title: "Peso Neto" },
  ];

  return (
    <div className="min-h-screen w-screen font-montserrat bg-[#F2F2F2]">
      {errorMessage && <Notification message={errorMessage} type="error" />}
      <div className="flex h-full">
        <Sidebar /> {/* Renderiza el componente Sidebar */}
        <div className="flex flex-col flex-1 w-screen h-screen overflow-hidden">
          <div className="flex justify-end">
            <Header /> {/* Renderiza el componente Header */}
          </div>
          <main className="m-2 p-4 h-full overflow-auto">
            <div className="m-2 p-2 xl:max-2xl:h-80 text-ocean1">
              <h3 className="text-xl text-[#182540] font-bold">INGRESOS</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-3">
                {staticData.ingresos.map((item, index) => (
                  <div
                    key={index}
                    className="p-2 bg-[#6D80A6] h-42 xl:h-52 hover:drop-shadow-2xl rounded-lg"
                  >
                    <div className="w-11/12 h-3/4 m-2 p-3 bg-[#F2F2F2] bg-opacity-25 border-l-3 border-[#F2F2F2] flex flex-col justify-center">
                      <h4 className="m-1 text-xl font-semibold xl:text-2xl">
                        {item.label}
                      </h4>
                      <p className="m-1 text-2xl font-bold xl:text-3xl">
                        {item.value}
                      </p>
                    </div>
                    <p className="m-1 ml-5 text-gray-950 font-medium xl:text-xl">
                      {item.change}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <h3 className="text-xl p-2 text-[#182540] font-bold">ULTIMA CONSULTA</h3>
            <div className="m-2 p-1 h-1/2 overflow-scroll">
              {loading ? (
                <p className="text-[#182540]">Cargando datos...</p>
              ) : (
                <div className="">
                  <Table columns={columns} data={apiData} /> {/* Renderiza el componente Table con los datos de la API */}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Inicio; // Exporta el componente Inicio como el componente por defecto del módulo



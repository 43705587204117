const InputField = ({ label, id, value, onChange, readOnly = false, type = "text" }) => {
  return (
    <div className="ml-4 p-2 flex w-11/12">
      {/* Etiqueta para el campo de entrada */}
      <label className="text-base w-1/4 font-bold m-2 block mr-4" htmlFor={id}>
        {label} {/* Muestra el texto de la etiqueta */}
      </label>
      {/* Campo de entrada */}
      <input
        id={id} // Identificador único para el input
        type={type} // Tipo de input (por defecto es texto)
        value={value} // Valor del input
        onChange={onChange} // Función de manejo de cambios en el input
        readOnly={readOnly} // Define si el campo es solo de lectura (por defecto es false)
        className="shadow w-3/4 text-base appearance-none border-2 border-[#6D80A6] rounded px-3 text-[#182540] leading-tight focus:outline-none focus:shadow-outline" // Estilos CSS
      />
    </div>
  );
};

export default InputField;



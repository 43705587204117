import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, DocumentMagnifyingGlassIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';
import { FiMenu, FiX } from 'react-icons/fi';
import logo from '../../assets/LogOcean.png';

const Sidebar = () => {
  // Estado para manejar la visibilidad del menú lateral en dispositivos móviles
  const [isOpen, setIsOpen] = useState(false);

  // Función para alternar la visibilidad del menú lateral
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Botón para abrir/cerrar el menú lateral en dispositivos móviles */}
      <div className="md:hidden fixed top-4 left-4 z-50">
        <button onClick={toggleSidebar} className="text-[#182540]">
          {isOpen ? <FiX size={30} className='text-[#F2F2F2]'/> : <FiMenu size={30} />}
        </button>
      </div>

      {/* Menú lateral */}
      <div className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:static transition-transform duration-300 ease-in-out w-64 bg-[#182540] text-white z-40`}>
        <div className="flex flex-col items-center m-5">
          {/* Logo del menú lateral */}
          <div className="bg-[#F2F2F2] w-40 h-40 rounded-full overflow-hidden flex items-center justify-center shadow-[0_35px_60px_-15px_rgba(250, 250, 250, 0.865)]">
            <img
              src={logo}
              alt="Logo Ocean Coal"
              className="object-contain w-32 h-32"
            />
          </div>
        </div>
        <div className="m-5 w-5/6">
          {/* Navegación del menú lateral */}
          <nav className="flex flex-col space-y-4">
            {/* Enlace a la página de inicio */}
            <div className="flex justify-start rounded text-[#F2F2F2] hover:bg-gray-300 hover:text-[#182540]">
              <li className="font-semibold py-2 px-4 ml-1 list-none flex flex-nowrap">
                <Link to="/inicio" className="flex items-center gap-3">
                  <HomeIcon className="w-6 ml-2" />
                  <p>INICIO</p>
                </Link>
              </li>
            </div>

            {/* Enlace a la página de consultas */}
            <div className="flex justify-start rounded text-[#F2F2F2] hover:bg-gray-300 hover:text-[#182540]">
              <li className="font-semibold py-2 px-4 ml-1 list-none">
                <Link to="/consultas" className="flex items-center gap-3">
                  <DocumentMagnifyingGlassIcon className="w-6 ml-2" />
                  <p>CONSULTAS</p>
                </Link>
              </li>
            </div>

            {/* Enlace a la página de soporte */}
            <div className="flex justify-start rounded text-[#F2F2F2] hover:bg-gray-300 hover:text-[#182540]">
              <li className="font-semibold py-2 px-4 ml-1 list-none">
                <Link to="/soporte" className="flex items-center gap-3">
                  <ChatBubbleLeftRightIcon className="w-6 ml-2" />
                  <p>SOPORTE</p>
                </Link>
              </li>
            </div>
          </nav>
        </div>
      </div>

      {/* Overlay para dispositivos móviles, que se muestra cuando el menú lateral está abierto */}
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 md:hidden" onClick={toggleSidebar}></div>}
    </>
  );
};

export default Sidebar;



import { useState, useEffect, useRef } from 'react'; // Importación de hooks de React
import { format } from 'date-fns'; // Importación de la función de formateo de fechas
import { es } from 'date-fns/locale'; // Importación del idioma español para formateo de fechas
import iconUser from '../../assets/user.svg'; // Importación de un ícono de usuario
import { useNavigate } from 'react-router-dom'; // Importación del hook de navegación de React Router

// Función para capitalizar la primera letra de una cadena
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar si el menú está abierto o cerrado
  const date = format(new Date(), 'eeee dd MMMM', { locale: es }); // Formateo de la fecha actual
  const capitalizedDate = date
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' '); // Capitaliza cada palabra de la fecha formateada

  const navigate = useNavigate(); // Hook para redirección
  const menuRef = useRef(null); // Referencia al menú para manejar clics fuera del menú

  // Función para alternar el estado del menú
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Función para manejar clics fuera del menú y cerrarlo
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // useEffect para agregar y limpiar el event listener de clics fuera del menú
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    // Aquí puedes agregar la lógica para cerrar sesión si es necesario
    localStorage.removeItem('userToken'); // Ejemplo de eliminación de token de autenticación
    navigate('/login'); // Redirige al usuario al login
  };

  return (
    <header className="text-ocean1 items-center h-14 m-2 p-2 pt-4 flex justify-between md:justify-end">
      <div className="flex items-center">
        <div className="flex items-center m-5 space-x-2 text-[#182540] font-semibold hidden md:flex">
          <span>Último acceso {capitalizedDate}</span> {/* Muestra la fecha de último acceso */}
        </div>
        <div className="relative flex items-center m-5 space-x-2 text-[#182540] font-semibold">
          <div className="hidden md:flex flex-col items-end">
            <span>Leidy Reyes</span> {/* Nombre del usuario */}
            <span>Bascula</span> {/* Rol o área del usuario */}
          </div>
          <button onClick={toggleMenu} className="focus:outline-none">
            <img src={iconUser} alt="Icon" className="w-8 h-8" /> {/* Ícono de usuario */}
          </button>
          {isOpen && (
            <div ref={menuRef} className="absolute top-12 right-0 bg-white text-black p-4 shadow-lg rounded-lg z-50 w-48">
              <div className={`flex flex-col items-start font-normal text-sm md:hidden`}>
                <span>Leidy Reyes</span> {/* Nombre del usuario (versión móvil) */}
                <span>Bascula</span> {/* Rol o área del usuario (versión móvil) */}
                <span>Último acceso {capitalizedDate}</span> {/* Fecha de último acceso (versión móvil) */}
                <button
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={handleLogout}
                >
                  Cerrar Sesión {/* Botón para cerrar sesión (versión móvil) */}
                </button>
              </div>
              <div className={`hidden md:flex flex-col items-start font-normal text-sm`}>
                <button
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={handleLogout}
                >
                  Cerrar Sesión {/* Botón para cerrar sesión (versión de escritorio) */}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;




